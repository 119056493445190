











































































import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'

@Component(
  {}
)
export default class CreatePicklPopup extends Vue {
  public subscriptionHistory: any = []
  public brandId: any = ''
  public limit: number = 100
  public offset: number = 0
  public brands: any[] = []
  public brandLimitPerPage: number = 1000
  public brandOffset: number = 0
  public hasMoreHistory: boolean = false
  public loadingHistory: boolean = false
  mounted () {
    this.getSubscriptionHistory()
    this.getBrands()
  }

  getSubscriptionHistory (reset = false) {
    this.loadingHistory = true
    if (reset) {
      this.offset = 0
      this.subscriptionHistory = []
    }
    let q = { brandId: this.brandId, limit: this.limit, offset: this.offset }
    this.$store.dispatch('getSubscriptionHistory', q).then((response: any) => {
      if (this.limit === q.limit && this.offset === q.offset) {
        this.offset += this.limit
        this.subscriptionHistory.push(...response.response.body.data)
        if (this.subscriptionHistory.length === response.response.body.count) {
          this.hasMoreHistory = false
        } else {
          this.hasMoreHistory = true
        }
        this.loadingHistory = false
      }
    })
  }

  getBrands (clear: boolean = true): void {
    this.$store.dispatch('getBrands', { limit: this.brandLimitPerPage, offset: this.brandOffset }).then((response: any) => {
      this.brands.push(...response.response.body.data)
      if (this.brands.length < response.response.body.count) {
        this.brandOffset += this.brandLimitPerPage
        this.getBrands()
      }
    })
  }
}
